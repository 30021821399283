<template>
  <div class="multi-predict-result">
    <div class="option-bar">
      <div :class="['option', idx == index ? 'selected' : '']" v-for="(formula, idx) in formulas" :key="formula"
        @click="changeIndex(idx)" :title="formula">
        {{ formula }}
      </div>
    </div>
    <div class="predict-result">
      <keep-alive>
        <prediction-result v-if="formula" :formula="formula" :score="score" :category="category"
          :mode="this.$route.query.mode">
        </prediction-result>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import PredictResult from "./PredictionResult.vue";
import { historyApi } from "../api/index";

export default {
  props: ["resultId"],
  data() {
    return {
      scores: null,
      formulas: null,
      category: null,
      index: 0,
    };
  },
  components: {
    "prediction-result": PredictResult,
  },
  created() {
    this.getData();
  },
  computed: {
    formula() {
      console.log("formula");
      return this.formulas[this.index];
    },
    score() {
      console.log("score");
      return this.scores[this.formulas[this.index]];
    },
  },
  methods: {
    changeIndex(index) {
      this.index = index;
    },
    getData() {
      historyApi
        .getResultById(this.resultId)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.formulas = res.data.input_content
              .match(/'(.*?)'/g)
              .map((item) => {
                return item.replace(/'/g, "");
              });
            const resultData = JSON.parse(
              res.data.output_score.replace(/'/g, '"')
            );
            this.scores = resultData;
            this.category = resultData.category_list;
            console.log(this.formulas);
          } else if (res.code == 401) {
            this.$store.commit("alertMsg", {
              msg: "Please login first!",
              type: "error",
            });
            this.$router.push({
              path: "/account/sign-in",
              query: { redirect: this.$route.path },
            });
          } else {
            this.$store.commit("alertMsg", {
              msg: res.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          this.$store.commit("alertMsg", {
            msg: err,
            type: "error",
          });
        });
    },
  },
};
</script>

<style scoped>
.multi-predict-result {
  display: flex;
  padding-top: 60px;
  /* background: linear-gradient(
    to bottom,
    var(--color1-dark),
    var(--color1-light)
  ); */
}

.predict-result {
  flex: 1;
  /* overflow: scroll; */
  /* height: 100vh; */
  margin-bottom: 50px;
}

.option-bar {
  width: 300px;
  border-right: 2px solid rgba(0, 0, 0, 0.5);
  min-height: 100vh;
  margin-right: 20px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* text-align: center; */
}

.option {
  box-sizing: border-box;
  width: 100%;
  margin: 5px 0;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  height: 50px;
  color: white;
  overflow: hidden;
  width: 80%;
  padding: 0 10px;
}

.option:hover,
.option.selected {
  background: var(--color2-dark);
}
</style>
